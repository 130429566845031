import { Footer } from "../Footer";
import { Layout } from "../Layout";
import { Block } from "../blocks/Block";
import { Cookie } from "../blocks/Cookie";
import { GoBack } from "../blocks/GoBack";
import { Hero } from "../blocks/Hero";
import { ImageVideo } from "../blocks/ImageVideo";
import { BlogPostText } from "../shared/BlogPostText";
import { graphql } from "gatsby";
import * as React from "react";

export const BlogPostTemplate = ({
  content,
  options,
  location,
  siteMeta,
  yoast,
  slug,
}) => {
  const { id, date } = content;
  const { title, text, media, image, navigationTextColor, menuColor } = content;

  const herotype: "case" | "landing" | "case-overview" = "case";
  const heroBackgroundType: "image" | "video" = "image";
  const heroProps = {
    id: "blogpostHero" + id,
    title: " ",
    subtitle: " ",
    navigationTextColor: navigationTextColor,
    images: [],
    backgroundImage: image,
    backgroundVideoFileDesktop: null,
    backgroundVideoFileMobile: null,
    backgroundVideoEmbed: null,
    backgroundVideoSettings: {
      autostart: false,
      loop: false,
    },
    videoType: "file",
    menuTitle: null,
    type: herotype,
    backgroundType: heroBackgroundType,
    menuColor: menuColor,
  };

  const imageVideoProps = {
    id: "blogpostImageVideo" + id,
    media: media,
    menuTitle: null,
    menuColor: "light",
  };

  const blogPostTextProps = {
    id: "blogpostText" + id,
    title,
    text,
    date,
    topOfPage: !image,
    menuColor: "dark",
  };

  const goBackProps = {
    id: "blogpostGoBack" + id,
    menuColor: "dark",
  };
  const blocks = [heroProps, blogPostTextProps, imageVideoProps, goBackProps];

  return (
    <Layout blocks={blocks} siteMeta={siteMeta} yoast={yoast} slug={slug}>
      <Block
        blogPost={true}
        navigationTextColor={heroProps.navigationTextColor}
        type={heroProps.type}
      />
      {image && <Hero {...heroProps} />}
      <BlogPostText {...blogPostTextProps} />
      <ImageVideo {...imageVideoProps} />
      <GoBack {...goBackProps} />
      <Cookie host={location.host} />
      <Footer {...options.contactOptions} logotype={options.logotypeFooter} />
    </Layout>
  );
};

export const query = graphql`
  fragment BlogPostFragment on WpBlogPost {
    date(formatString: "MMMM Y", locale: "sv")
    id
    blogOptions {
      title
      text
      navigationTextColor
      menuColor
      image {
        localFile {
          url
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    media {
      type
      image {
        localFile {
          url
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      videoType
      videoFile {
        sourceUrl
        size: fileSize
      }
      videoSettings {
        autostart
        loop
      }
      videoEmbed
    }
  }
`;
