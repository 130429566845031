import { BlogPostTemplate } from "../components/BlogPostTemplate";
import { withStore } from "../libs/mobx/withStore";
import { graphql } from "gatsby";
import React from "react";

const Blogpost = ({ data, location }) => {
  const {
    wpBlogPost: { blogOptions, slug, seo },
    wp: { themeGeneralSettings, generalSettings },
  } = data;
  const content = blogOptions;
  const options = themeGeneralSettings;
  return (
    <BlogPostTemplate
      content={content}
      options={options}
      location={location}
      siteMeta={generalSettings}
      yoast={seo}
      slug={slug}
    />
  );
};
// In development, disable seo query to avoid errors

export const pageQuery = graphql`
  query BlogpostById($id: String!) {
    wpBlogPost(id: { eq: $id }) {
      ...BlogPostFragment
      slug
      seo {
        title
        metaDesc
        canonical
        opengraphImage {
          sourceUrl
        }
      }
    }
    wp {
      ...FooterFragment
      generalSettings {
        title
        description
        url
      }
    }
  }
`;

export default withStore(Blogpost);
