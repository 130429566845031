import * as React from "react";
import s from "./BlogPostText.module.scss";
import { Text } from "../Text";
import { createMarkup } from "../../../libs/utils";
import { Section } from "../Section";
import { inject } from "mobx-react";
import { NavStore } from "stores";

interface BlogPostTextProps {
  id: string;
  date: string;
  title: string;
  text: string;
  topOfPage: boolean;
  navStore?: NavStore;
  menu_text?: string;
}

@inject("navStore")
export class BlogPostText extends React.Component<BlogPostTextProps> {
  render() {
    const {
      id,
      date,
      text,
      title,
      topOfPage,
      navStore: { navbarHeight },
    } = this.props;
    const extraPadding = topOfPage ? navbarHeight : 0;
    return (
      <Section anchor={id} sectionName="BlogPostText">
        <div style={{ paddingTop: extraPadding }}>
          <Text h2 color={"blue"}>
            <div className={s.title}>{title}</div>
          </Text>
          <div className={s.date}>
            <Text p color={"blue_light"}>
              {date}
            </Text>
          </div>
          <Text>
            {text && <div dangerouslySetInnerHTML={createMarkup(text)} />}
          </Text>
        </div>
      </Section>
    );
  }
}
